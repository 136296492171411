import React from 'react';
import { NavLink } from 'react-router-dom';

// import '../assets/css/style.css';

const FrontHeader = () => {
  function CloseButton(){
    const offcanvasclosebtn = document.getElementById('offcanvasclosebtn');
    offcanvasclosebtn.click();
}
  return (
    <>
      <section className="header-sticky">
        <section className="bg-green py-1">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-10 col-md-10 col-sm-12 col-12">
                <div className="row">
                  {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <i className="fas fa-map-marker-alt black-color font-12 me-1"></i><span className="black-color font-12">Location
                      : </span>
                    <span className="text-white font-12">26, Matey Square, Nagpur</span>
                  </div> */}
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                    <a href='mailto:Info@Urbanenv.In' className='text-decoration-none'>
                    <i className="fas fa-envelope  black-color font-12 me-1"></i><span className="black-color font-12">Email :
                    </span>
                    <span className="text-white font-12">Info@Urbanenv.In</span>
                    </a>
                  </div>
                  <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12">
                    
                    <i className="fas fa-clock black-color font-12 me-1"></i><span className="black-color font-12">Working Hours:
                    </span>
                    <span className="text-white font-12">Mon-Sat: 10.00am to 6.00pm</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-2 col-md-2 col-sm-12 col-12 d-flex justify-content-end align-items-center">
                <div className="mobile-none">
                <a href="https://www.facebook.com/share/VLpL2naH8piYVVdy/?mibextid=qi2Omg" target='_blank' className='text-decoration-none'><i className="fab fa-facebook text-white font-18 me-2 facebook"></i></a>
                <a href="https://www.instagram.com/urbanenviro_wm/?igsh=MWNzeGpidWdqc3p0eA%3D%3D" target='_blank'  className='text-decoration-none'><i className="fab fa-instagram text-white font-18 me-2 instagram"></i></a>
               
                  <a href="https://x.com/i/flow/login?redirect_after_login=%2FUrbanenviroltd" target='_blank'  className='text-decoration-none'><i className="fab fa-twitter-square text-white  font-18 me-2 twitter"></i></a>

                  <a href="https://www.youtube.com/@UrbanEnvirowmltd" target='_blank'  className='text-decoration-none'><i className="fab fa-youtube text-white  font-18 me-2 youtube"></i></a>

                  <a href="https://www.linkedin.com/in/urban-enviro-waste-management-limited-645057291/" target='_blank'  className='text-decoration-none'><i className="fab fa-linkedin text-white  font-18 me-2  linkedin"></i></a>
                
                </div>
              </div>
            </div>
          </div>
        </section>

        <header className="bg-white">
          <div className="container">
            <div className="row d-flex justify-content-between align-items-center">
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6">
                <NavLink to='home'>
                  <img src="../assets/img/logo.png" alt="" className="w-75 mb-2" />
                </NavLink>
              </div>
              <div className="col-xl-10 col-lg-10 col-md-2 col-sm-2 col-1 d-flex justify-content-end ">
                <nav className="navbar navbar-expand-lg desktop-view">
                  <div className='container-fluid main-navigation'>
                    <div className='collapse navbar-collapse pe-0'>
                      <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
                        <li className="nav-item dropdown">
                          <NavLink className="nav-link " role="button" data-bs-toggle="dropdown"
                            aria-expanded="false" >
                            About
                          </NavLink>
                          <ul className="dropdown-menu drop-first">
                            <li className="nav-item">
                              <NavLink to="About-us" className="nav-link border-bottom-1px" >
                                About URBAN
                              </NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to='Chairman-Message' className="nav-link border-bottom-1px"  >
                                Chairman’s Message
                              </NavLink>
                            </li>
                            <li className="nav-item ">
                              <NavLink className="nav-link border-bottom-1px" to="BoardofDirectors">
                                Board of Directors
                              </NavLink>
                            </li>
                            <li className="nav-item ">
                              <NavLink className="nav-link" to="BoardCommittees" >
                                Board Committees
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link" to="Services">Services</NavLink>
                        </li>
                        <li className="nav-item dropdown">
                          <NavLink className="nav-link" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            Investors
                          </NavLink>
                          <ul className="dropdown-menu drop-first">
                            <li className="nav-item dropend border-bottom-1px">
                              <NavLink className="nav-link border-bottom-1px" >
                                Financials Reporting
                              </NavLink>
                              <ul className="dropdown-menu drop-second">
                                <li className="border-bottom-1px">
                                  <NavLink className="dropdown-item" to="FinancialsReports">Financials Reports</NavLink>
                                  </li>
                                <li className="border-bottom-1px">
                                  <NavLink className="dropdown-item" to="Investorpresenation">INVESTOR PRESENATATION</NavLink>
                                  </li>
                                <li><NavLink className="dropdown-item" to="AnnualReturn">Annual Report </NavLink></li>
                              </ul>
                            </li>
                            <li className="nav-item dropend border-bottom-1px">
                              <NavLink className="nav-link border-bottom-1px" >
                                IPO Documents
                              </NavLink>
                              <ul className='dropdown-menu drop-second'>
                                <li className="border-bottom-1px"><NavLink className="dropdown-item" to="Prospectus">Prospectus</NavLink></li>
                                <li className="border-bottom-1px"><NavLink className="dropdown-item" to="MaterialDocuments">Material Documents</NavLink></li>
                                <li className="border-bottom-1px"><NavLink className="dropdown-item" to="MaterialContracts">Material Contracts </NavLink></li>
                              </ul>
                            </li>
                            <li className="nav-item dropend border-bottom-1px">
                              <NavLink className="nav-link " role="button" data-bs-toggle="dropdown"
                                aria-expanded="false">
                                Stock Prices
                              </NavLink>
                              <ul className="dropdown-menu drop-second ">
                                <li><a className="dropdown-item" target='_blank' href="https://www.nseindia.com/get-quotes/equity?symbol=URBAN">NSE</a></li>
                              </ul>
                            </li> 
                            <li><NavLink className="dropdown-item border-bottom-1px ps-2" to="CorporateAnnouncements" >
                              Corporate Announcements</NavLink></li>
                            <li className="nav-item dropend ">
                              <NavLink className="nav-link" role="button" data-bs-toggle="dropdown"
                                aria-expanded="false">
                                Shareholders Information
                              </NavLink>
                              <ul className="dropdown-menu drop-second">
                                <li className="border-bottom-1px"><NavLink className="dropdown-item" to="CorporateGovernanceReport">Corporate Governance Report</NavLink></li>
                                <li className="border-bottom-1px"><NavLink className="dropdown-item" to="ShareholdingPattern">Shareholding Pattern</NavLink></li>
                                <li className="border-bottom-1px"><NavLink className="dropdown-item" to="InvestorContacts">Investor Contacts</NavLink></li>
                                <li className="border-bottom-1px"><NavLink className="dropdown-item" to="Policies">Policies</NavLink></li>
                                <li className="pe-2"><NavLink className="dropdown-item" to="Disclosures">Disclosures under Regulation 46 <br />and
                                  47 of SEBI (LODR) Regulations, 2015</NavLink></li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link" to="OngoingProjects">Projects</NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link" to="NewsMedia">News & Media</NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link" to="Gallery">Gallery</NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link" to="Career">Career</NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link" to="ContactUs">Contact Us</NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
                <div className="mobile-view">
                  <i className="fas fa-bars" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="offcanvas offcanvas-end offcanvas-none" tabindex="-1" id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header">
              <img src="../assets/img/logo.png" alt="" className="w-25" />
              <button type="button" className="btn-close text-reset" id="offcanvasclosebtn" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body py-0">
              <div className="accordion accordion-flush new-acc  border-bottom mb-2 pb-2" id="accordionFlushExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button className="accordion-button collapsed p-0" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      About
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body px-0 pt-2 pb-0">
                      <ul className=" ps-0  list-style-none mb-0">
                        <li className="pb-2 border-bottom"  onClick={CloseButton}><NavLink className="text-dark text-decoration-none" 
                          to="About-us">  About URBAN</NavLink>
                        </li>
                        <li className="pb-2 border-bottom"  onClick={CloseButton}><NavLink className="text-dark text-decoration-none"
                         to='Chairman-Message'>  Chairman’s Message</NavLink></li>
                        <li className="pb-2 border-bottom"  onClick={CloseButton}><NavLink className="text-dark text-decoration-none"
                         to="BoardofDirectors">  Board of Directors</NavLink></li>
                        <li  onClick={CloseButton}><NavLink className="text-dark text-decoration-none"
                         to="BoardCommittees">   Board Committees</NavLink></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <p className=" border-bottom"  onClick={CloseButton}><NavLink  to="Services"
                className="text-dark text-decoration-none ankar-hover">Services</NavLink></p>
                <div className="accordion accordion-flush new-acc  border-bottom mb-2 pb-2" id="accordionFlushExampleone">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button className="accordion-button collapsed p-0" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                       Investors
                    </button>
                  </h2>
                  <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExampleone">
                    <div className="accordion-body px-0 pt-2 pb-0">
                      <ul className=" ps-0  list-style-none mb-0">
                        <li className="pb-2 border-bottom" onClick={CloseButton}><NavLink className="text-dark text-decoration-none"
                         to="FinancialsReports"> Financials Reports</NavLink>
                        </li>
                        <li className="pb-2 border-bottom" onClick={CloseButton}><NavLink className="text-dark text-decoration-none"
                          to="AnnualReturn">Annual Report </NavLink></li>
                        <li className="pb-2 border-bottom" onClick={CloseButton}><NavLink className="text-dark text-decoration-none"
                          to="Prospectus">Prospectus</NavLink></li>
                        <li className="pb-2 border-bottom" onClick={CloseButton}><NavLink className="text-dark text-decoration-none"
                          to="MaterialDocuments">Material Documents </NavLink></li>
                        <li className="pb-2 border-bottom" onClick={CloseButton}><NavLink className="text-dark text-decoration-none"
                          to="MaterialContracts">Material Contracts </NavLink></li>
                        <li className="pb-2 border-bottom" onClick={CloseButton}><NavLink className="text-dark text-decoration-none"
                          to='https://www.nseindia.com/get-quotes/equity?symbol=URBAN'>NSE</NavLink></li>
                        <li className="pb-2 border-bottom" onClick={CloseButton}><NavLink className="text-dark text-decoration-none"
                         to="CorporateAnnouncements" > Corporate Announcements</NavLink></li>
                        <li className="pb-2 border-bottom" onClick={CloseButton}><NavLink className="text-dark text-decoration-none"
                         to="ShareholdingPattern">Shareholding Pattern</NavLink></li>
                        <li className="pb-2 border-bottom" onClick={CloseButton}><NavLink className="text-dark text-decoration-none"
                         to="InvestorContacts">Investor Contacts</NavLink></li>
                        <li className="pb-2 border-bottom" onClick={CloseButton}><NavLink className="text-dark text-decoration-none"
                         to="Policies">Policies</NavLink></li>
                        <li className="" onClick={CloseButton}><NavLink className="text-dark text-decoration-none"
                          to="Disclosures">Disclosures under Regulation 46 </NavLink></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <p className=" border-bottom"  onClick={CloseButton}><NavLink to="OngoingProjects" className="text-dark text-decoration-none ankar-hover">
                Projects</NavLink></p>
              <p className=" border-bottom"  onClick={CloseButton}><NavLink to="NewsMedia" className="text-dark text-decoration-none ankar-hover">News &
                Media</NavLink></p>
              <p className=" border-bottom"  onClick={CloseButton}><NavLink to="Career" className="text-dark text-decoration-none ankar-hover">Career</NavLink>
              </p>
              <p className=" border-bottom"  onClick={CloseButton}><NavLink to="Gallery" className="text-dark text-decoration-none ankar-hover">Gallery</NavLink>
              </p>
              <p className=" border-bottom"  onClick={CloseButton}><NavLink to="ContactUs" className="text-dark text-decoration-none ankar-hover">Contact
                Us</NavLink></p>
            </div>
          </div>
        </header>
      </section>
    </>
  )
}

export default FrontHeader
