import React from 'react'

const Career = () => {
    return (
        <>
            <section class="breadcrum-career">
                <div class="container-fluid">
                    <div class="row">
                        <div class="d-flex justify-content-center align-items-center text-decoration-none breadcrum_anchor">
                            <h1 class="text-white">Career
                            </h1>
                        </div>
                    </div>
                </div>
            </section>
            <section class="mt-5 animation-element slide-up testimonial in-view">
                <div class="container">
                    <div class="box-leader p-3">
                        <h4 class="color-blue blogs_titles">Current Openings : Environmental Engineer</h4>
                    
                        <h5 class='mb-2'>Job Description:</h5>
                        <p class="">
                        We are looking for a skilled Environmental Engineer to join Urban Enviro Waste Management Limited, a leading solid waste management company. The ideal candidate should have in-depth knowledge of environmental engineering principles, solid waste management processes, and regulatory compliance.
                        </p>

                        <h5 class='mt-3'>Key Responsibilities:</h5>
                        <ul>
                            <li>Oversee waste management projects, ensuring compliance with environmental regulations.</li>
                            <li>Develop strategies for sustainable waste disposal, recycling, and treatment.</li>
                            <li>Conduct environmental impact assessments and risk analyses. </li>
                            <li>Prepare technical reports and presentations on environmental performance and waste management.</li>
                            <li>Collaborate with government agencies, clients, and stakeholders for project approvals and environmental compliance.</li>
                            <li>Implement innovative solutions to optimize waste collection, processing, and disposal.</li>
                            <li>Monitor and evaluate the effectiveness of waste management programs.</li>
                        </ul>
                        <h5 class='mt-3'>Qualifications & Skills:</h5>
                        <ul >
                            <li>Bachelor’s/Master’s degree in Environmental Engineering or a related field.</li>
                            <li>Strong understanding of solid waste management, environmental laws, and sustainability practices.</li>
                            <li>Excellent analytical, problem-solving, and communication skills.</li>
                            <li>Proficiency in preparing technical reports and delivering presentations to stakeholders.</li>
                            <li>Experience with waste processing technologies (composting, recycling, landfill management, etc.) is a plus.</li>
                            <li>Ability to work in a team and manage multiple projects simultaneously.</li>
                        </ul>
                    </div>
                    {/* <div class="box-leader p-3 mt-3">

                        <p class="mb-0">2.Required Manager (IEC Activity) / Specialist (IEC Activity)</p>
                        <ul>
                            <li>Any Graduate/post graduate having Computer knowledge (MS-Office)</li>
                            <li>Experience in development and design of IEC materials and very good communication, teamwork and interpersonal skills</li>
                            <li>Responsible for: Development, planning, organisation, implementation and monitoring of all IEC activities for Gujarat state</li>
                            <li>Language known- English and Hindi</li>
                            <li>Start date: Immediately</li>
                        </ul>
                        <p className='mb-1'><b>Location:</b>Vadodara (Gujarat), Jaipur (Rajasthan)</p>


                    </div> */}
                    <p class="text-justify mt-3">URBAN is an organization where we focus on development of employees as much as on
                        development of innovative solutions since, we firmly believe that the growth of an organization is directly
                        proportional to the growth of its employees.
                        <br />
                        <br />
                        Today URBAN is growing at a rapid phase. This growth necessitates greater investment in talent. At URBAN, we are
                        looking for the enthusiastic, dynamic individuals who would not only take the company to newer heights but take
                        charge of its growth and values as future leaders.
                        <br />
                        <br />
                        By joining URBAN, you will become part of a team, with the potential to become part of a truly nation and
                        worldwide service provider of waste management Industry. People with strong values of honesty, passion to excel
                        and a zest for doing something better and the enthusiasm for continuous development are welcome to be a part of
                        the URBAN family.
                        <br />
                        <br />
                        We sincerely thank all candidates who choose to apply, however, depending on the requirement and only those
                        selected for a further interview will be contacted.
                        <br />
                        Recent job openings are available at Current Openings
                    </p>
                    <div class="box-leader-box p-2">
                        <h4 class="color-blue blogs_titles">Send in your resume to:</h4>
                        <p class="mb-0">Urban Enviro Waste Management Limited, 26, Matey Square, BPCL petrol pump, Gopal Nagar Road,
                            Nagpur-440022</p>
                        <p className='mb-1'>Email: <a href="mailto:info@urbanenv.in" class=" text-dark">info@urbanenv.in</a></p>
                        <p>Contact no.: <a href="tel:9225216560" class=" text-dark"> 9225216560</a></p>

                    </div>
                </div>
            </section>
        </>
    )
}
export default Career
